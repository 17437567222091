export const ORGANIZATION_URL = `${process.env.REACT_APP_BACK_USER}/organization`;

export const ORGANIZATION_UPDATE = {
  url: ORGANIZATION_URL + "/:id",
  method: "PATCH",
};

export const ORGANIZATION_GET_ONE = {
  url: ORGANIZATION_URL + "/:id",
  method: "GET",
};
