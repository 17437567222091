export const AUTH_URL = process.env.REACT_APP_BACK_USER;

export const PROFILE_GET = {
  url: AUTH_URL + "/profile",
  method: "GET",
};

export const REGISTER = {
  url: AUTH_URL + "/register",
  method: "POST",
};
