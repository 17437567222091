export const USER_URL = `${process.env.REACT_APP_BACK_USER}/users`;

export const USER_GET_ALL = {
  url: USER_URL,
  method: "GET",
};
export const USER_CREATE = {
  url: USER_URL,
  method: "POST",
};
export const USER_UPDATE = {
  url: USER_URL + "/:id",
  method: "PUT",
};
export const USER_REMOVE = {
  url: USER_URL + "/:id",
  method: "DELETE",
};
