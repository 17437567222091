import { Navigate, useRoutes } from "react-router-dom";
// auth
import AuthGuard from "../auth/AuthGuard";
// layouts
import CompactLayout from "../layouts/compact";
// config
import {
  LoginPage,
  Page404,
  PlanningPage,
  RegisterPage,
  DashboardPage,
  SettingsPage,
  AddProjectPage,
} from "./elements";
import GuestGuard from "src/auth/GuestGuard";
import { PATH_AUTH, PATH_DASHBOARD } from "./paths";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      children: [
        { path: "/", element: <Navigate to={PATH_AUTH.login} replace /> },
      ],
    },
    {
      path: PATH_DASHBOARD.root,
      children: [
        {
          path: PATH_DASHBOARD.root,
          element: (
            <AuthGuard>
              <DashboardPage />
            </AuthGuard>
          ),
        },
        {
          path: PATH_DASHBOARD.general.planning,
          element: (
            <AuthGuard>
              <PlanningPage />
            </AuthGuard>
          ),
        },
        {
          path: PATH_DASHBOARD.general.addProject,
          element: (
            <AuthGuard>
              <AddProjectPage />
            </AuthGuard>
          ),
        },
        {
          path: PATH_DASHBOARD.general.settings,
          element: (
            <AuthGuard>
              <SettingsPage />{" "}
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: PATH_AUTH.root,
      element: <CompactLayout />,
      children: [
        {
          path: PATH_AUTH.register,
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        {
          path: PATH_AUTH.login,
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: "404", element: <Page404 /> }],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
