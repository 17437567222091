// @mui
import { enUS, frFR } from "@mui/material/locale";

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: " EN",
    value: "en",
    systemValue: enUS,
    icon: "🇬🇧",
  },
  {
    label: " FR",
    value: "fr",
    systemValue: frFR,
    icon: "🇫🇷",
  },
];

export const defaultLang = allLangs[1]; // French
