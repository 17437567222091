import { Suspense, lazy } from "react";
// components
import LoadingScreen from "../components/loading-screen";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import("../pages/LoginPage")));
export const RegisterPage = Loadable(
  lazy(() => import("../pages/RegisterPage"))
);
export const PlanningPage = Loadable(
  lazy(() => import("../pages/PlanningPage"))
);
export const DashboardPage = Loadable(
  lazy(() => import("../pages/DashboardPage"))
);
export const SettingsPage = Loadable(
  lazy(() => import("../pages/SettingsPage"))
);
export const AddProjectPage = Loadable(
  lazy(() => import("../pages/AddProjectPage"))
);

export const Page404 = Loadable(lazy(() => import("../pages/Page404")));
