import localStorageAvailable from "src/utils/localStorageAvailable";

export const serviceClient = async (req, extra = {}) => {
  try {
    const storageAvailable = localStorageAvailable();
    const { url, method } = req;
    const { params, body, id, blob } = extra;

    const options = {
      method,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: storageAvailable
          ? `Bearer ${localStorage.getItem("accessToken")}`
          : "",
      },
    };
    if (body) {
      options["body"] = JSON.stringify(body);
    }
    const res = await fetch(
      `${url.replace(":id", id)}?${params || ""}`,
      options
    );

    if (!res.ok) {
      if (res.status === 401) {
        localStorage.removeItem("accessToken");
        window.location.reload();
      }

      const errorData = await res.json();
      throw new Error(errorData.message || "Request failed");
    }

    const responseData = blob ? await res.blob() : await res.json();

    return responseData;
  } catch (error) {
    throw new Error(error.message || "Request failed");
  }
};
