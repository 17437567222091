export const MISSION_SPECIFICATIONS_URL = `${process.env.REACT_APP_BACK_PROJECT}/mission-specifications`;

export const MISSION_SPECIFICATION_CREATE = {
  url: MISSION_SPECIFICATIONS_URL,
  method: "POST",
};

export const MISSION_SPECIFICATION_GET_ALL = {
  url: MISSION_SPECIFICATIONS_URL,
  method: "GET",
};

export const MISSION_SPECIFICATION_WAYBILL = {
  url: MISSION_SPECIFICATIONS_URL + "/:id/waybill",
  method: "GET",
};