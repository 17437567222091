import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// utils
import localStorageAvailable from "../utils/localStorageAvailable";
//
import { defaultLang } from "./config-lang";
//
import Backend from "i18next-http-backend";

// ----------------------------------------------------------------------

let lng = defaultLang.value;

const storageAvailable = localStorageAvailable();

if (storageAvailable) {
  lng = localStorage.getItem("i18nextLng") || defaultLang.value;
}

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
   /* resources: {
      fr: { translations: frLocales },
      en: { translations: enLocales },
    },*/
    lng,
    fallbackLng: defaultLang.value,
    debug: true,
    ns: ["planning"],
    defaultNS: "planning",
    interpolation: {
      escapeValue: false,
    },
    saveMissing: true,
    backend: {
      loadPath: `${process.env.REACT_APP_BACK_I18N}/i18n/{{lng}}/{{ns}}`,
      addPath: `${process.env.REACT_APP_BACK_I18N}/i18n/{{lng}}/{{ns}}`,
      parsePayload: function(namespace, key, fallbackValue) { return { name: key, value: fallbackValue} },
    },
  });

export default i18n;
