import PropTypes from "prop-types";
import { createContext, useContext, useMemo, useCallback } from "react";
// hooks
import useLocalStorage from "../../hooks/useLocalStorage";
//
import { defaultSettings } from "./config-setting";
import { defaultPreset, getPresets, presetsOption } from "./presets";

// ----------------------------------------------------------------------

const initialState = {
  ...defaultSettings,
  // Language
  onChangeLanguage: () => {},
  // Color
  onChangeColorPresets: () => {},
  presetsColor: defaultPreset,
  presetsOption: [],
  // Stretch
  onToggleStretch: () => {},
  // Reset
  onResetSetting: () => {},
};

// ----------------------------------------------------------------------

export const SettingsContext = createContext(initialState);

export const useSettingsContext = () => {
  const context = useContext(SettingsContext);

  if (!context)
    throw new Error("useSettingsContext must be use inside SettingsProvider");

  return context;
};

// ----------------------------------------------------------------------

SettingsProvider.propTypes = {
  children: PropTypes.node,
};

export function SettingsProvider({ children }) {
  const [settings, setSettings] = useLocalStorage("settings", defaultSettings);

  // Language
  const onChangeLanguage = useCallback(
    (value) => {
      const themeLanguage = value;
      localStorage.setItem("i18nextLng", themeLanguage);
      setSettings({ ...settings, themeLanguage });
      window.location.reload();
    },

    [setSettings, settings]
  );

  // Color
  const onChangeColorPresets = useCallback(
    (event) => {
      const themeColorPresets = event.target.value;
      setSettings({ ...settings, themeColorPresets });
    },
    [setSettings, settings]
  );

  // Reset
  const onResetSetting = useCallback(() => {
    setSettings(defaultSettings);
    localStorage.setItem("i18nextLng", defaultSettings.themeLanguage);
    window.location.reload();
  }, [setSettings]);

  const memoizedValue = useMemo(
    () => ({
      ...settings,
      // Language
      onChangeLanguage,
      // Color
      onChangeColorPresets,
      presetsOption,
      presetsColor: getPresets(settings.themeColorPresets),
      // Reset
      onResetSetting,
    }),
    [
      settings,
      // Language
      onChangeLanguage,
      // Color
      onChangeColorPresets,
      // Reset
      onResetSetting,
    ]
  );

  return (
    <SettingsContext.Provider value={memoizedValue}>
      {children}
    </SettingsContext.Provider>
  );
}
