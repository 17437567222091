export const EMPLOYEE_URL = `${process.env.REACT_APP_BACK_EMPLOYEE}/employees`;

export const EMPLOYEE_GET_ALL = {
  url: EMPLOYEE_URL,
  method: "GET",
};
export const EMPLOYEE_COUNT = {
  url: EMPLOYEE_URL + "/count",
  method: "GET",
};
export const EMPLOYEE_GET_ONE = {
  url: EMPLOYEE_URL + "/:id",
  method: "GET",
};
export const EMPLOYEE_CREATE = {
  url: EMPLOYEE_URL,
  method: "POST",
};
export const EMPLOYEE_UPDATE = {
  url: EMPLOYEE_URL + "/:id",
  method: "PUT",
};
export const EMPLOYEE_REMOVE = {
  url: EMPLOYEE_URL + "/:id",
  method: "DELETE",
};
