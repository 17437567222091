export const SETTING_URL = `${process.env.REACT_APP_BACK_SETTINGS}/settings`;

export const SETTING_UPDATE = {
  url: SETTING_URL + "/:id",
  method: "PATCH",
};

export const SETTING_CREATE = {
  url: SETTING_URL,
  method: "POST",
};
