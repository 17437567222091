import PropTypes from "prop-types";
import {
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from "react";
// utils
import axios from "../utils/axios";
import localStorageAvailable from "../utils/localStorageAvailable";
import { isValidToken, setSession } from "./utils";
import { serviceClient } from "src/api";
import { PROFILE_GET, REGISTER } from "src/api/routes/auth";
import Roles from "src/utils/role";

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const AuthActionTypes = {
  INITIAL: "INITIAL",
  LOGIN: "LOGIN",
  REGISTER: "REGISTER",
  UPDATE: "UPDATE",
  LOGOUT: "LOGOUT",
};

const initialAction = (isInitialized, isAuthenticated, user) => ({
  type: AuthActionTypes.INITIAL,
  payload: { isInitialized, isAuthenticated, user },
});

const loginAction = (user) => ({
  type: AuthActionTypes.LOGIN,
  payload: { user },
});

const registerAction = (user) => ({
  type: AuthActionTypes.REGISTER,
  payload: { user },
});

const updateAction = (user) => ({
  type: AuthActionTypes.UPDATE,
  payload: { user },
});

const logoutAction = () => ({
  type: AuthActionTypes.LOGOUT,
});

const reducer = (state, action) => {
  switch (action.type) {
    case AuthActionTypes.INITIAL:
    case AuthActionTypes.LOGIN:
    case AuthActionTypes.REGISTER:
    case AuthActionTypes.UPDATE:
      return {
        ...state,
        isInitialized: action.payload.isInitialized ?? true,
        isAuthenticated: action.payload.isAuthenticated ?? true,
        user: action.payload.user,
      };
    case AuthActionTypes.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};

export const AuthContext = createContext(null);

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable
        ? localStorage.getItem("accessToken")
        : null;

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await serviceClient(PROFILE_GET);

        const user = response;

        dispatch(initialAction(true, true, user));
      } else {
        dispatch(initialAction(true, false, null));
      }
    } catch (error) {
      console.error(error);
      dispatch(initialAction(false, null));
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const login = useCallback(async (email, password) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BACK_USER}/login`,
      {
        email,
        password,
      }
    );

    const { accessToken } = response.data;
    let { user } = response.data;

    setSession(accessToken);

    if (!user) {
      user = await serviceClient(PROFILE_GET);
    }

    dispatch(loginAction(user));
  }, []);

  const register = useCallback(async (data) => {
    const response = await serviceClient(REGISTER, { body: data });

    let { user, accessToken } = response;
    setSession(accessToken);

    if (!user) {
      user = await serviceClient(PROFILE_GET);
    }

    dispatch(registerAction(user));
  }, []);

  const logout = useCallback(() => {
    setSession(null);
    dispatch(logoutAction());
  }, []);

  const updateUser = useCallback(async () => {
    const user = await serviceClient(PROFILE_GET);
    dispatch(updateAction(user));
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      isAdmin: state.user?.roles?.includes(Roles.admin),
      isManager: state.user?.roles?.includes(Roles.division_admin),
      isUser: state.user?.roles?.includes(Roles.user),
      method: "jwt",
      login,
      register,
      logout,
      updateUser,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      logout,
      register,
      updateUser,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
