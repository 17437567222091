import PropTypes from "prop-types";
import { createContext, useContext, useCallback, useState } from "react";

// ----------------------------------------------------------------------
export const defaultSettings = {
  eventModal: false,
  eventModalType: "create", // create OR update
  employeeModal: false,
  truckModal: false,
  selectedEvent: null,
  filter: false,
  filters: "",
  planningShouldUpdate: false,
};

const initialState = {
  ...defaultSettings,
  // Event
  changeEventModal: (bool, eventModalType) => {},
  changeEmployeeModal: (bool) => {},
  changeTruckModal: (bool) => {},
  //Event
  setEvent: (event) => {},
  setFilter: (bool) => {},
  setFilters: (string) => {},
  setPlanningShouldUpdate: (bool) => {},
};

// ----------------------------------------------------------------------

export const CalendarContext = createContext(initialState);

export const useCalendarContext = () => {
  const context = useContext(CalendarContext);

  if (!context)
    throw new Error("useCalendarContext must be use inside CalendarProvider");

  return context;
};

// ----------------------------------------------------------------------

CalendarProvider.propTypes = {
  children: PropTypes.node,
};

export function CalendarProvider({ children }) {
  const [settings, setSettings] = useState(defaultSettings);

  // Event
  const changeEventModal = useCallback(
    (eventModal, eventModalType = "create") => {
      setSettings((prevSettings) => ({
        ...prevSettings,
        eventModal,
        selectedEvent: eventModal ? prevSettings.selectedEvent : null,
        eventModalType,
      }));
    },
    [setSettings, settings]
  );

  const changeEmployeeModal = useCallback(
    (employeeModal) => {
      setSettings((prevSettings) => ({
        ...prevSettings,
        employeeModal,
        selectedEvent: employeeModal ? prevSettings.selectedEvent : null,
      }));
    },
    [setSettings, settings]
  );

  const changeTruckModal = useCallback(
    (truckModal) => {
      setSettings((prevSettings) => ({
        ...prevSettings,
        truckModal,
        selectedEvent: truckModal ? prevSettings.selectedEvent : null,
      }));
    },
    [setSettings, settings]
  );

  //Event
  const setEvent = useCallback(
    (selectedEvent) => {
      setSettings((prevSettings) => ({
        ...prevSettings,
        selectedEvent,
      }));
    },
    [setSettings, settings]
  );

  const setFilter = useCallback(
    (filter) => {
      setSettings((prevSettings) => ({
        ...prevSettings,
        filter,
      }));
    },
    [setSettings, settings]
  );

  const setFilters = useCallback(
    (filters) => {
      setSettings((prevSettings) => ({
        ...prevSettings,
        filters,
      }));
    },
    [setSettings, settings]
  );

  const setPlanningShouldUpdate = useCallback(
    (planningShouldUpdate) => {
      setSettings((prevSettings) => ({
        ...prevSettings,
        planningShouldUpdate,
      }));
    },
    [setSettings, settings]
  );

  const memoizedValue = {
    ...settings,
    // EventModal
    changeEventModal,
    changeEmployeeModal,
    changeTruckModal,
    // Event
    setEvent,
    setFilter,
    setFilters,
    setPlanningShouldUpdate,
  };

  return (
    <CalendarContext.Provider value={memoizedValue}>
      {children}
    </CalendarContext.Provider>
  );
}
