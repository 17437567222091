export const EVENT_URL = `${process.env.REACT_APP_BACK_PROJECT}/events`;

export const EVENT_UPDATE = {
  url: EVENT_URL + "/:id",
  method: "PATCH",
};

export const EVENT_REMOVE = {
  url: EVENT_URL + "/:id",
  method: "DELETE",
};

export const EVENT_GET_ALL = {
  url: EVENT_URL,
  method: "GET",
};
