export const PLANNING_URL = `${process.env.REACT_APP_BACK_PLANNING}/planning`;

export const PLANNING_GET_ALL = {
  url: PLANNING_URL,
  method: "GET",
};
export const PLANNING_GET_ONE = {
  url: PLANNING_URL + "/:id",
  method: "GET",
};
export const PLANNING_CREATE = {
  url: PLANNING_URL,
  method: "POST",
};
export const PLANNING_UPDATE = {
  url: PLANNING_URL + "/:id",
  method: "PATCH",
};
export const PLANNING_REMOVE = {
  url: PLANNING_URL + "/:id",
  method: "DELETE",
};

export const PLANNING_ASSIGN_TRUCKS = {
  url: PLANNING_URL + "/:id/trucks",
  method: "PUT",
};

export const PLANNING_DOWNLOAD_WAYBILL = {
  url: PLANNING_URL + "/:id/waybill",
  method: "GET",
};
