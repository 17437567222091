export const PROJECT_URL = `${process.env.REACT_APP_BACK_PROJECT}/projects`;

export const PROJECT_GET = {
  url: PROJECT_URL + "/:id",
  method: "GET",
};

export const PROJECT_UPDATE = {
  url: PROJECT_URL + "/:id",
  method: "PATCH",
};

export const PROJECT_REMOVE = {
  url: PROJECT_URL + "/:id",
  method: "DELETE",
};