// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_HOME = "/";
const ROOTS_AUTH = "/auth";
export const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_HOME = {
  homePage: ROOTS_HOME,
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    planning: path(ROOTS_DASHBOARD, "/planning"),
    settings: path(ROOTS_DASHBOARD, "/settings"),
    addProject: path(ROOTS_DASHBOARD, "/project"),
  },
};
