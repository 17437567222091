export const FLEET_URL = `${process.env.REACT_APP_BACK_FLEET}/fleet`;

export const TRUCK_GET_ALL = {
  url: FLEET_URL,
  method: "GET",
};
export const TRUCK_COUNT = {
  url: FLEET_URL + "/count",
  method: "GET",
};
export const TRUCK_GET_ONE = {
  url: FLEET_URL + "/:id",
  method: "GET",
};
export const TRUCK_CREATE = {
  url: FLEET_URL,
  method: "POST",
};
export const TRUCK_UPDATE = {
  url: FLEET_URL + "/:id",
  method: "PUT",
};
export const TRUCK_REMOVE = {
  url: FLEET_URL + "/:id",
  method: "DELETE",
};
