export const DIVISION_URL = `${process.env.REACT_APP_BACK_USER}/division`;

export const DIVISION_GET_ALL = {
  url: DIVISION_URL,
  method: "GET",
};
export const DIVISION_GET_ONE = {
  url: DIVISION_URL + "/:id",
  method: "GET",
};
export const DIVISION_CREATE = {
  url: DIVISION_URL,
  method: "POST",
};
export const DIVISION_UPDATE = {
  url: DIVISION_URL + "/:id",
  method: "PUT",
};
export const DIVISION_REMOVE = {
  url: DIVISION_URL + "/:id",
  method: "DELETE",
};
