import { Outlet } from "react-router-dom";
// @mui
import { Stack, Container } from "@mui/material";
//
import Header from "./Header";

// ----------------------------------------------------------------------

export default function CompactLayout() {
  return (
    <>
      <Container sx={{ marginBottom: "5.5em" }}>
        <Header />
      </Container>

      <Container component="main">
        <Stack
          sx={{
            m: "auto",
            textAlign: "center",
            maxWidth: "500px",
            justifyContent: "center",
          }}
        >
          <Outlet />
        </Stack>
      </Container>
    </>
  );
}
